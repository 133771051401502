import '../../styles/App.css';

function SupportPage() {
    return (
      <div className="page_container">
      <h1>Support</h1>
      <p>Welcome to our support page. How can we assist you today?</p>
      <h2>Contact Us</h2>
      <p>You can reach us at support@example.com or call us at 123-456-7890.</p>
      {/* <h2>FAQ</h2>
      <p>Here are some frequently asked questions and their answers:</p>
      <ul>
          <li>
              <h3>Question 1</h3>
              <p>Answer 1</p>
          </li>
          <li>
              <h3>Question 2</h3>
              <p>Answer 2</p>
          </li>
      </ul> */}
  </div>
      );
    }
    


export default SupportPage;